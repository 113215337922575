<template>
  <div style="padding-left: 16px;">
    <el-button size="small" @click="BackSelect">返回</el-button>
  </div>
  <div class="moreList" v-if="material_list.length != 1">
    <div class="templateList">
      <div
        class="templateListItem"
        v-for="item in material_list"
        :key="item.id"
        @mouseenter="mouseenter(item.id)"
        @mouseleave="mouseleave(item.id)"
      >
        <div class="imgBox">
          <el-image
            :src="`${item.img}`"
            fit="cover"
            v-loading="loading"
            element-loading-text="图片正在加载中..."
            element-loading-background="rgba(0, 0, 0, 0.5)"
            @click="selectTem(item.id, 0)"
          >
            <template #placeholder>
              <span class="Text" style="color:#2b82ff;">正在加载中...</span>
            </template>
          </el-image>
        </div>
        <div class="operateBtn">
          <div class="hiddenBox" v-show="operateBtn == item.id">
            <el-button type="primary" size="small" @click="editParam">编辑</el-button>
            <el-button type="primary" size="small" @click="selectOther(item.id)">选择其他模板</el-button>
          </div>
        </div>
      </div>
    </div>
    <div class="downloadBox">
      <el-button type="primary" size="small" @click="PackDownload">打包下载</el-button>
      <p class="Text">本月免费下载次数剩余：{{ downloadCount }}次</p>
    </div>
  </div>
  <div class="single" v-else>
    <div class="templateList">
      <div
        class="templateListItem1"
        v-for="item in material_list"
        :key="item.id"
        @mouseenter="mouseenter(item.id)"
        @mouseleave="mouseleave(item.id)"
      >
        <div class="imgBox">
          <el-image :src="`${item.img}`" fit="cover" v-loading="loading" lazy @click="selectTem(item.id, 0)" />
        </div>
        <div class="operateBtn1">
          <el-button type="primary" size="small" @click="editParam">编辑</el-button>
          <el-button type="primary" size="small" @click="selectOther(item.id)">选择其他模板</el-button>
        </div>
        <div style="margin-top: 60px;">
          <el-button type="primary" size="small" @click="PackDownload">下载</el-button>
          <p class="Text">本月免费下载次数剩余：{{ downloadCount }}次</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import JSZip from 'jszip'
import FileSaver from 'file-saver'
import { ElLoading } from 'element-plus'
export default {
  name: 'MaterialResult',
  props: ['urlId'],
  data() {
    return {
      loading: false,
      isSelectId: 0,
      single: 0,
      operateBtn: 0,
      material_list: [],
      downloadCount: 0,
      editParamVisible: false,
      urlForm: {
        discount: '',
        price_selling: '',
        price_market: '',
        currency: ''
      },
      urlFormRules: {},
      fileIds: [],
      files: []
    }
  },
  mounted() {
    this.getMaterialList()
  },
  methods: {
    BackSelect() {
      this.$router.push({
        name: 'MaterialSelect',
        query: {
          id: this.urlId
        }
      })
    },
    // 获取素材模板
    async getMaterialList() {
      this.loading = true
      this.$http
        .post('material/set_material', {
          s_type: 1,
          id: this.urlId
        })
        .then(({ data: res }) => {
          if (res.code == 100) {
            return this.$message.error(`${res.msg}`)
          }
          this.material_list = res.data
          var single = this.material_list.length
          this.$bus.emit('getSingle', { isSingle: single })
        })
        .finally(() => {
          this.loading = false
        })
    },
    selectTem(id, type) {
      this.isSelectId = id
    },
    mouseenter(id) {
      this.material_list.forEach(item => {
        if (item.id == id) {
          this.operateBtn = id
        }
      })
    },
    mouseleave() {
      this.operateBtn = 0
    },
    selectOther(id) {
      this.$router.push({
        name: 'MaterialTemplate',
        query: {
          id: id,
          urlId: this.urlId
        }
      })
    },
    editParam() {
      this.$router.push({
        name: 'MaterialForm',
        query: {
          id: this.urlId
        }
      })
    },
    editParamClose() {
      this.$refs.urlFormRef.resetFields()
    },
    cancelEditParam() {
      this.editParamVisible = false
    },
    async PackDownload() {
      const loading = ElLoading.service({
        spinner: 'el-icon-loading',
        lock: true,
        text: '正在下载中，请稍后...'
      })
      this.material_list.forEach(item => {
        this.fileIds.push(item.id)
      })
      this.$http
        .post('material/download_img', {
          ids: this.fileIds
        })
        .then(({ data: res }) => {
          if (res.code == 100) {
            return this.$message.error(`${res.msg}`)
          }
          if (res.data.length > 1) {
            res.data.forEach((item, index) => {
              this.files.push({ url: item, fileName: `图片${index}.png` })
            })
            this.filesToRar(this.files, '素材压缩包')
          } else {
            res.data.forEach(item => {
              window.location.href = item
              return
            })
          }
        })
        .finally(() => {
          loading.close()
        })
    },
    filesToRar(arrImages, filename) {
      let _this = this
      let zip = new JSZip()
      let cache = {}
      let promises = []
      _this.title = '正在加载压缩文件'
      for (let item of arrImages) {
        const promise = _this.getImgArrayBuffer(item.url).then(data => {
          // 下载文件, 并存成ArrayBuffer对象(blob)
          zip.file(item.fileName, data, { binary: true }) // 逐个添加文件
          cache[item.fileName] = data
        })
        promises.push(promise)
      }
      Promise.all(promises)
        .then(() => {
          zip.generateAsync({ type: 'blob' }).then(content => {
            _this.title = '正在压缩'
            // 生成二进制流
            FileSaver.saveAs(content, filename) // 利用file-saver保存文件 自定义文件名
            _this.title = '压缩完成'
          })
        })
        .catch(res => {
          _this.$message.error('文件打包下载失败')
        })
    },
    //获取文件blob
    getImgArrayBuffer(url) {
      let _this = this
      return new Promise((resolve, reject) => {
        //通过请求获取文件blob格式
        let xmlhttp = new XMLHttpRequest()
        xmlhttp.open('GET', url, true)
        xmlhttp.responseType = 'blob'
        xmlhttp.onload = function() {
          if (this.status == 200) {
            resolve(this.response)
          } else {
            reject(this.status)
          }
        }
        xmlhttp.send()
      })
    }
  }
}
</script>

<style lang="less" scoped>
.templateList {
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  padding: 16px;
  .templateListItem,
  .templateListItem1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 55px;
    margin-bottom: 15px;
    text-align: center;
    cursor: pointer;
    .imgBox {
      position: relative;
      width: 315px;
      height: 315px;
      margin-bottom: 15px;
      .el-image {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
      }
    }
    .operateBtn {
      width: 100%;
      height: 40px;
    }
  }
  .templateListItem1 {
    margin: 0 auto;
    .imgBox {
      width: 600px;
      height: 600px;
    }
    .operateBtn {
      display: flex;
      justify-content: space-between;
      width: 80%;
      height: 40px;
    }
    .operateBtn1 {
      margin-top: 15px;
    }
  }
}
.downloadBox {
  text-align: center;
}
@media screen and (max-width: 1550px) {
  .templateList .templateListItem .imgBox {
    width: 310px;
    height: 310px;
  }
}
@media screen and (max-width: 1440px) {
  .templateList .templateListItem .imgBox {
    width: 290px;
    height: 290px;
  }
}
@media screen and (max-width: 1440px) {
  .templateList .templateListItem .imgBox {
    width: 280px;
    height: 280px;
  }
}
</style>
