<template>
  <el-card class="box-card">
    <template #header>
      <div class="card-header">
        <span class="SubHead">素材管理</span>
      </div>
    </template>
    <el-row justify="center">
      <el-col :span="12">
        <router-link to="/MaterialMake">
          <div class="imgBox">
            <h3>素材制作</h3>
            <img src="../../../assets/images/materialHome_left.png" />
            <div class="btnBox">
              点击进入
            </div>
          </div>
        </router-link>
      </el-col>
      <el-col :span="12">
        <router-link to="/fodderWork">
          <div class="imgBox">
            <h3>创意工单</h3>
            <img src="../../../assets/images/materialHome_right.png" />
            <div class="btnBox">
              点击进入
            </div>
          </div>
        </router-link>
      </el-col>
    </el-row>
  </el-card>
</template>

<script>
export default {
  name: 'MaterialHome',
  data() {
    return {}
  },
  methods: {}
}
</script>

<style lang="less" scoped>
a {
  text-decoration: none;
}
.imgBox {
  position: relative;
  width: 500px;
  height: 740px;
  text-align: center;
  box-shadow: var(--el-box-shadow-light);
  border-radius: 8px;
  padding-top: 60px;
  box-sizing: border-box;
  margin: 0 auto;
  overflow: hidden;
  h3 {
    font-size: 24px;
    color: #262626;
    margin-bottom: 40px;
  }
  img {
    width: 100%;
  }
  .btnBox {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 60px;
    line-height: 60px;
    font-size: 16px;
    color: #fff;
    text-align: center;
    background-color: #2b82ff;
  }
}
</style>
