<template>
  <div class="imgContent">
    <!-- 左侧 -->
    <div id="secondStep" class="imgTemplate" :class="isToggle ? 'toggle' : ''">
      <el-scrollbar height="100%">
        <div class="templateList">
          <div class="templateListItem" v-for="item in material_list" :key="item.id">
            <el-image :src="`${base.admin_url}/${item.image}`" fit="cover" lazy  @click="selectTem(item.id, 0)" />
            <div class="selectMask" v-show="isSelectId == item.id">
              <img src="../../assets/iconImg/icon_select.png" alt="" class="icon_select" />
            </div>
          </div>
        </div>
      </el-scrollbar>
      <div class="tiggerBtn" @click="tiggerBtn">
        <i class="iconfont icon-ylleft" :class="isToggle ? 'icon-ylright' : ''"></i>
      </div>
    </div>
    <!-- 中间 -->
    <div class="upload_box">
      <div class="top_bar">
        <span class="SubHead">创意素材创作</span>
        <el-row :gutter="20">
          <el-col :span="12" v-show="commonPath != ''">
            <el-button type="primary" size="small" @click="openImgCut">
              <i class="iconfont icon-ylreplace"></i>
              替换图片
            </el-button>
          </el-col>
          <el-col :span="12">
            <el-button type="primary" size="small" :disabled="downloadPath == ''" @click="downloadImgBtn">
              <i class="iconfont icon-yldownload"></i>
              下载
            </el-button>
          </el-col>
        </el-row>
      </div>
      <div>
        <div id="firstStep" class="upload_content" v-if="clientPath == ''">
          <p class="Text">请先上传您需要设计的商品图，即可选择您心仪的模板进行设计哦</p>
          <el-button type="primary" @click="openImgCut">点击上传图片</el-button>
        </div>
        <div class="upload_content1" v-else>
          <el-image
            style="width: 100%; height: 100%;display: flex;align-items: center;justify-content: center;"
            :src="`${base.admin_url}/${commonPath}`"
            fit="cover"
            v-loading="loading"
            element-loading-text="图片正在加载中..."
            element-loading-background="rgba(0, 0, 0, 0.5)"
          >
          <template #placeholder>
            <span class="Text" style="color:#2b82ff;">图片加载中...</span>
          </template>
          </el-image>
        </div>
      </div>
    </div>
    <!-- 右侧 -->
    <div id="thirdStep" class="right_bar" :class="isToggle1 ? 'toggle1' : ''">
      <div class="tiggerRightBtn" @click="tiggerRightBtn">
        <i class="iconfont icon-ylright" :class="isToggle1 ? 'icon-ylleft' : ''"></i>
      </div>
      <img src="../../assets/imgFodderDemo.png" alt="" v-show="isDriver == 1" />
      <el-form
        ref="paramFormRef"
        label-position="top"
        :model="paramForm"
        label-width="120px"
        class="paramForm"
        v-show="paramForm != ''"
      >
        <template v-for="(item, index) in paramForm" :key="index">
          <el-form-item
            :label="item.name"
            :prop="'[' + index + '].value'"
            :rules="[{ required: true, message: '请输入' + item.name, trigger: 'blur' }]"
          >
            <el-input size="small" :placeholder="item.point" v-model.trim="item.value" />
          </el-form-item>
        </template>

        <el-form-item>
          <el-button type="primary" size="small" class="saveParamBtn" @click="saveParam">保存</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>

  <!-- 裁剪插件弹框 -->
  <!-- 设置或者换绑邮箱对话框 -->
  <el-dialog
    v-model="imgCutDialogVisible"
    @close="imgCutClose"
    title="上传图片"
    width="800px"
    custom-class="imgCutDialog"
  >
    <div style="width: 100%;height:400px;">
      <vueCropper
        ref="cropper"
        :img="CropperOption.img"
        :fixed="true"
        :outputSize="CropperOption.outputSize"
        :autoCrop="CropperOption.autoCrop"
        :full="CropperOption.full"
        @realTime="realTime"
      ></vueCropper>
      <div class="footer-btn">
        <el-upload
          ref="imgCutUpload"
          class="imgCutUpload"
          accept=".jpeg,.png,.jpg"
          action=""
          :before-upload="beforeImgCutUpload"
          :limit="1"
        >
          <el-button type="primary" size="small">上传图片</el-button>
          <span class="Text" style="margin-left:16px;color: #F56C6C;">支持格式(.jpeg, .png, .jpg)</span>
        </el-upload>
      </div>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button size="small" type="primary" @click="confirmImgBtn('blob')">确定</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import $ from 'jquery'
import Driver from 'driver.js'
import 'driver.js/dist/driver.min.css'
import base from '../../request/base'
export default {
  name: 'ImgFodder',
  components: {},
  data() {
    return {
      base: base,
      loading: false,
      baseResourceUrl: base.admin_url,
      driverImgFodder: null,
      intro: '',
      isDriver: 0,
      isToggle: false,
      isToggle1: true,
      isSelectId: 0,
      material_list: [],
      c_id: 0,
      // 上传的图
      clientImage: {},
      commonPath: '',
      // 确定上传图片返回的地址
      clientPath: '',
      // 生成的图地址
      downloadPath: '',
      paramForm: [],
      rules: {
        value: [{ required: true, message: '请输入参数', trigger: 'blur' }]
      },
      // paramFormmRule: {
      //   discount: [{ required: true, message: '请输入折扣', trigger: 'blur' }],
      //   original: [{ required: true, message: '请输入原价', trigger: 'blur' }],
      //   current: [{ required: true, message: '请输入现价', trigger: 'blur' }],
      //   text: [{ required: true, message: '请输入号召文字', trigger: 'blur' }]
      // },
      imgCutDialogVisible: false,
      CropperOption: {
        img: '',
        size: 1,
        full: true,
        outputSize: 1,
        outputType: 'png',
        canMove: true,
        fixedBox: false,
        original: false,
        canMoveBox: true,
        autoCrop: true,
        // 只有自动截图开启 宽度高度才生效
        autoCropWidth: 800,
        autoCropHeight: 800,
        centerBox: false,
        high: true,
        max: 99999
      }
    }
  },
  mounted() {
    this.getMaterialList()
  },
  beforeUnmount() {
    if (this.driverImgFodder != null) {
      this.driverImgFodder.reset()
    }
  },
  methods: {
    // 获取素材模板
    async getMaterialList() {
      const { data: res } = await this.$http.post('material/material_list')
      this.material_list = res.data
      this.intro = res.intro
      if (this.intro == 1) {
        this.imgFodderGuide()
      }
    },

    // 选择素材模板
    async selectTem(id, type) {
      if (this.CropperOption.img == '') {
        return this.$message.warning('请先上传您的图片！')
      }
      if (this.isSelectId != 0 && type == 0) {
        const confirmResult = await this.$confirm(`未保存修改内容将清除！`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).catch(err => err)

        if (confirmResult !== 'confirm') {
          return
        } else {
          this.isSelectId = id
          this.isToggle1 = false
          // 选择素材模板时触发
          this.getFormParma()
          this.loading = true
          this.$http
            .post('material/set_material', {
              id: id,
              c_id: this.c_id,
              img: this.clientPath
            })
            .then(res => {
              this.c_id = res.data.id
              this.commonPath = res.data.path
            })
            .finally(() => {
              this.loading = false
            })
        }
      }

      this.isSelectId = id
      this.isToggle1 = false
      // 选择素材模板时触发
      this.getFormParma()
      this.loading = true
      this.$http
        .post('material/set_material', {
          id: id,
          c_id: this.c_id,
          img: this.clientPath
        })
        .then(res => {
          this.c_id = res.data.id
          this.commonPath = res.data.path
        })
        .finally(() => {
          this.loading = false
        })
    },

    // 获取素材参数
    async getFormParma() {
      const { data: res } = await this.$http.post('material/material_config', {
        id: this.isSelectId
      })
      this.paramForm = res.data
    },

    // 上传自己的图片
    openImgCut() {
      this.imgCutDialogVisible = true
    },

    // 缩进两侧栏
    tiggerBtn() {
      this.isToggle = !this.isToggle
    },
    tiggerRightBtn() {
      if(this.isSelectId == 0) {
        return this.$message.warning('请先上传图片并选择模板！')
      }
      this.isToggle1 = !this.isToggle1
    },

    // 关闭对话框
    imgCutClose() {},

    // 上传自己图片前
    beforeImgCutUpload(file) {
      var img = file.name.substring(file.name.lastIndexOf('.') + 1)
      const suffix = img === 'jpg'
      const suffix2 = img === 'png'
      const suffix3 = img === 'jpeg'
      const suffix4 = img == 'JPG'
      const suffix5 = img == 'PNG'
      const suffix6 = img == 'JPEG'
      if (!suffix && !suffix2 && !suffix3 && !suffix4 && !suffix5 && !suffix6) {
        this.$message.error('仅支持发送图片！')
        return false
      }

      const reader = new FileReader()
      // 转化为base64
      reader.readAsDataURL(file)
      reader.onload = () => {
        // 获取到需要剪裁的图片 展示到剪裁框中
        this.CropperOption.img = reader.result
      }

      return false
    },
    realTime(data) {
      this.$refs.cropper.getCropBlob(data => {
        this.clientImage = new FormData()
        this.clientImage.append('file', data)
      })
    },
    // 确定上传自己的图片
    confirmImgBtn(type) {
      this.imgCutDialogVisible = false

      this.loading = true
      this.$http
        .post('file/uploadfile_material', this.clientImage)
        .then(res => {
          this.clientPath = res.data.path
          this.commonPath = res.data.path
          if (this.isSelectId != 0) {
            this.selectTem(this.isSelectId, 1)
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    // 保存按钮
    saveParam() {
      this.$refs.paramFormRef.validate(async vaild => {
        if (!vaild) return

        this.loading = true
        this.$http
          .post('material/set_material_txt', {
            id: this.c_id,
            m_id: this.isSelectId,
            config: this.paramForm
          })
          .then(res => {
            this.commonPath = res.data.path
            this.downloadPath = res.data.path1
          })
          .finally(() => {
            this.loading = false
          })
      })
    },
    // 下载成品图
    downloadImgBtn() {
      window.location.href = this.downloadPath
    },
    // 新手引导
    AddScroll() {
      var top = $('.el-main').scrollTop()
      $('.el-main').on('scroll.unable', function(e) {
        $('.el-main').scrollTop(top)
      })
    },
    RemoveScroll() {
      $('.el-main').unbind('scroll.unable')
    },
    imgFodderGuide() {
      this.$nextTick(() => {
        this.driverImgFodder = new Driver({
          allowClose: false,
          opacity: 0.5,
          nextBtnText: '下一步',
          closeBtnText: '跳过',
          doneBtnText: '完成',
          onReset: () => {
            this.isDriver = 0
            this.isToggle1 = true
            this.RemoveScroll()
            this.$http.post('user/set_intro', {
              type: 'material'
            })
          },
          onHighlightStarted: () => {
            this.$nextTick(() => {
              this.AddScroll()
            })
          },
          onNext: () => {
            this.RemoveScroll()
            this.isDriver = 1
            this.isToggle1 = false
          }
        })

        this.driverImgFodder.defineSteps([
          {
            element: '#firstStep',
            popover: {
              className: 'first-step-popover-class',
              title: '新手指引(1/3)',
              description: '请在此上传产品图',
              position: 'left'
            }
          },
          {
            element: '#secondStep',
            popover: {
              title: '新手指引(2/3)',
              description: '选择模板',
              position: 'right'
            }
          },
          {
            element: '#thirdStep',
            popover: {
              title: '新手指引(3/3)',
              description: '编辑文字并保存，即可生成创意',
              position: 'left',
              closeBtnText: '',
              doneBtnText: '完成'
            }
          }
        ])
        this.driverImgFodder.start()
      })
    }
  }
}
</script>
<style lang="less" scoped>
div {
  box-sizing: border-box;
}

.imgContent {
  display: flex;
  width: 100%;
  height: 700px;
  overflow: hidden;
  box-shadow: var(--el-box-shadow-light);
}
.imgTemplate {
  position: relative;
  width: 20%;
  width: 290px;
  min-width: 290px;
  background-color: #fff;
  box-shadow: 4px 0 4px 0 rgba(0, 0, 0, 0.06);
  transition: all 0.2s;
  .tiggerBtn {
    position: absolute;
    left: 100%;
    top: 0;
    bottom: 0;
    width: 14px;
    height: 80px;
    line-height: 80px;
    text-align: left;
    color: #999;
    border-radius: 0 20px 20px 0;
    background: #fff;
    box-shadow: 4px 0 4px 0 rgba(0, 0, 0, 0.06);
    margin: auto;
    cursor: pointer;
    .iconfont {
      font-size: 12px;
    }
  }
}
.toggle {
  width: 0;
  min-width: 0;
  transform: translateX(-100%);
}
.toggle1 {
  width: 0 !important;
  min-width: 0 !important;
  transform: translateX(100%);
  padding: 0 !important;
}
.templateList {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  height: 100%;
  padding: 16px;
  .templateListItem {
    position: relative;
    width: 120px;
    height: 120px;
    margin-bottom: 16px;
    cursor: pointer;
    .selectMask {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.1);
      z-index: 1;
      .icon_select {
        position: absolute;
        right: 0;
        bottom: 0;
        width: 30px;
        height: 30px;
      }
    }
    .el-image {
      width: 100%;
      height: 100%;
    }
  }
}
.upload_box {
  width: 100%;
  background-color: #fff;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  .top_bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 48px;
    background-color: #fff;
    border-bottom: 1px solid #e4e7ed;
    padding: 0 20px;
    .iconfont {
      font-size: 14px;
    }
  }
}

.right_bar {
  position: relative;
  width: 20%;
  width: 250px;
  min-width: 250px;
  background-color: #fff;
  box-shadow: -1px 0 4px rgba(0, 0, 0, 0.06);
  padding: 16px;
  transition: all 0.2s;
  .icon-ylleft:before {
    content: '\e621' !important;
  }
  :deep(.el-button) {
    span {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .iconfont {
    font-size: 14px;
    margin-right: 6px;
  }
  .tiggerRightBtn {
    position: absolute;
    right: 100%;
    top: 0;
    bottom: 0;
    width: 14px;
    height: 80px;
    line-height: 80px;
    text-align: right;
    color: #999;
    border-radius: 20px 0 0 20px;
    background: #fff;
    box-shadow: -1px 0 4px rgba(0, 0, 0, 0.06);
    margin: auto;
    cursor: pointer;
    .iconfont {
      font-size: 12px;
      margin-right: 0;
    }
  }
}
.paramForm {
  margin: 16px 0;
}
.saveParamBtn {
  display: block;
  width: 100%;
  margin-top: 50px;
}
.upload_content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 600px;
  height: 600px;
  border: 1px dashed #2b82ff;
  margin: 24px auto;
}
.upload_content1 {
  width: 600px;
  height: 600px;
  margin: 24px auto;
}
.imgCutUpload {
  margin-top: 16px;
}
@media screen and (max-width: 1440px) {
  .upload_content,
  .upload_content1 {
    width: 500px;
    height: 500px;
  }
}
</style>
