<template>
  <el-card class="box-card">
    <template #header>
      <div class="card-header">
        <span class="SubHead">素材制作</span>
        <div>
          <!-- <router-link to=""><span class="LinkText" v-if="isDownload">查看下载记录</span></router-link> -->
        </div>
      </div>
    </template>
    <router-view />
  </el-card>
</template>

<script>
export default {
  name: 'MaterialMake',
  data() {
    return {
      isDownload: false
    }
  },
  watch: {
    $route(to, from) {
      if (to.path == '/materialMake/materialSelect') {
        this.isDownload = true
      } else {
        this.isDownload = false
      }
    }
  },
  mounted() {
    if (this.$route.path == '/materialMake/materialSelect') {
      this.isDownload = true
    } else {
      this.isDownload = false
    }
  },
  methods: {}
}
</script>

<style lang="less" scoped>
:deep(.el-card__body) {
  padding-bottom: 60px;
}
a {
  text-decoration: none;
}
.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
