<template>
  <div style="padding-left: 16px;">
    <el-button size="small" @click="BackResult">返回</el-button>
  </div>
  <div class="templateList">
    <div
      class="templateListItem"
      v-for="item in material_list"
      :key="item.m_id"
      @mouseenter="mouseenter(item.m_id)"
      @mouseleave="mouseleave(item.m_id)"
    >
      <div class="imgBox">
        <el-image
          :src="`${item.img}`"
          fit="cover"
          v-loading="loading"
          element-loading-text="图片正在加载中..."
          element-loading-background="rgba(0, 0, 0, 0.5)"
        >
          <template #placeholder>
            <span class="Text" style="color:#2b82ff;">正在加载中...</span>
          </template>
        </el-image>
        <div class="selectMask" v-show="isSelectId == item.m_id">
          <img src="../../../assets/iconImg/icon_select.png" alt="" class="icon_select" />
        </div>
      </div>
      <div class="operateBtn">
        <div v-show="operateBtn == item.m_id">
          <el-button type="primary" size="small" @click="selectTem(item.m_id, item.img1)">选择此模板</el-button>
        </div>
      </div>
    </div>
  </div>
  <!-- 分页器 -->
  <el-pagination
    @size-change="handleSizeChange"
    @current-change="handleCurrentChange"
    :current-page="queryInfo.page"
    :page-sizes="[8]"
    :page-size="queryInfo.pagesize"
    layout="total, sizes, prev, pager, next, jumper"
    :total="total"
    background
  >
  </el-pagination>
</template>

<script>
import { ElLoading } from 'element-plus'
export default {
  name: 'MaterialTemplate',
  props: ['imgId', 'urlId'],
  data() {
    return {
      loading: false,
      isSelectId: 0,
      operateBtn: 0,
      material_list: [],
      // 表单查询
      queryInfo: {
        query: '',
        page: 1,
        pagesize: 8
      },
      total: 0
    }
  },
  mounted() {
    this.getMaterialList()
  },
  methods: {
    BackResult() {
      this.$router.push({
        name: 'MaterialResult',
        query: {
          id: this.urlId
        }
      })
    },
    // 获取素材模板
    async getMaterialList() {
      const loading = ElLoading.service({
        spinner: 'el-icon-loading',
        lock: true,
        text: '正在加载中，请稍后...'
      })
      this.loading = true
      this.$http
        .post('material/other_material', {
          page: this.queryInfo.page,
          id: this.imgId
        })
        .then(({ data: res }) => {
          this.material_list = res.data
          this.total = res.total
        })
        .finally(() => {
          loading.close()
          this.loading = false
        })
    },
    async selectTem(id, img1) {
      this.isSelectId = id
      const { data: res } = await this.$http.post('material/other_material', {
        type: 1,
        img: img1,
        m_id: id,
        id: this.imgId
      })
      if (res.code == 200) {
        this.$router.push({
          name: 'MaterialResult',
          query: {
            id: this.urlId
          }
        })
      }
    },
    mouseenter(id) {
      this.material_list.forEach(item => {
        if (item.m_id == id) {
          this.operateBtn = id
        }
      })
    },
    mouseleave() {
      this.operateBtn = 0
    },
    // 展示条数改变触发的函数
    handleSizeChange(newSize) {
      this.queryInfo.pagesize = newSize
      this.getMaterialList()
    },
    handleCurrentChange(newPage) {
      this.queryInfo.page = newPage
      this.getMaterialList()
    }
  }
}
</script>

<style lang="less" scoped>
.templateList {
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  padding: 16px;
  .templateListItem {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 55px;
    margin-bottom: 15px;
    text-align: center;
    cursor: pointer;
    .imgBox {
      position: relative;
      width: 315px;
      height: 315px;
      margin-bottom: 15px;
      .selectMask {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.1);
        z-index: 1;
        .icon_select {
          position: absolute;
          right: 0;
          bottom: 0;
          width: 30px;
          height: 30px;
        }
      }
      .el-image {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
      }
    }
    .operateBtn {
      height: 40px;
    }
  }
}

@media screen and (max-width: 1550px) {
  .templateList .templateListItem .imgBox {
    width: 310px;
    height: 310px;
  }
}
@media screen and (max-width: 1440px) {
  .templateList .templateListItem .imgBox {
    width: 290px;
    height: 290px;
  }
}
@media screen and (max-width: 1440px) {
  .templateList .templateListItem .imgBox {
    width: 280px;
    height: 280px;
  }
}
</style>
